<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->
        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head
                    :page-head="pagehead"
                    :show-profile-details="true"
                    :user-data="userData"
                    :user-type="userType"
                ></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <b-tabs class="ideas-tabs">
                                <b-tab
                                    v-for="(tab, index) in tabs"
                                    :key="index"
                                    :title="$t(tab.title)"
                                    :active="activeTab === tab.order"
                                    v-on:click="changeTab(tab.order)"
                                >
                                    <div
                                        class="filter-box"
                                        v-if="tab.order === 2"
                                    >
                                        <div class="search-box">
                                            <base-input
                                                :required="false"
                                                :placeholder="$t('Search')"
                                                @input="search"
                                            />
                                        </div>
                                        <div class="dropdown-box">
                                            <v-select
                                                :options="IdeaStates"
                                                v-model="selectedState"
                                            >
                                            </v-select>
                                            <div
                                                v-if="
                                                    selectedState.en_text !=
                                                        'Filter by Status'
                                                "
                                                @click="clearStatus"
                                                class="clear"
                                            >
                                                <svg
                                                    fill="#009b74"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    viewBox="0 0 409.81 409.81"
                                                >
                                                    <use
                                                        xlink:href="#menu-close-icon"
                                                    ></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <ideas-card
                                        v-for="(idea, index) in ideas"
                                        :description="false"
                                        :date="true"
                                        :idea="idea"
                                        :show-idea-state="false"
                                        :key="`idea-${index}`"
                                        :to="{
                                            name: 'user-idea-details',
                                            params: { uuid: idea.uuid },
                                        }"
                                        :showReviews="false"
                                        :showIdeators="false"
                                        :showOpco="true"
                                        :showCategory="true"
                                    ></ideas-card>
                                    <base-loader v-if="activeIdeasLoadding" />
                                    <p
                                        v-if="
                                            !activeIdeasLoadding &&
                                                !ideas.length &&
                                                dashboardSettings &&
                                                dashboardSettings.dashboard_settings
                                        "
                                        class="no-idea-text"
                                        v-html="
                                            dashboardSettings
                                                .dashboard_settings[
                                                `no_idea_msg_${$i18n.locale}`
                                            ]
                                        "
                                    ></p>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import IdeasCard from '@/modules/ideas/components/list/IdeasCard'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
    components: {
        IdeasCard,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            ideas: [],
            activeIdeasLoadding: true,
            filters: {
                page_size: 6,
                page: 1,
            },
            userData: {},
            userType: '',
            tabs: [{ title: 'All ideas', order: 2 }],
            activeTab: 1,
            IdeaStates: [
                {
                    en_text: 'Filter by Status',
                    text: this.$t('Filter by Status'),
                    disabled: true,
                },
                {
                    en_text: 'submitted',
                    text: this.$t('Submitted'),
                },
                {
                    en_text: 'approved',
                    text: this.$t('Approved'),
                },
                {
                    en_text: 'resubmit',
                    text: this.$t('Resubmit'),
                },
                {
                    en_text: 'dismissed',
                    text: this.$t('Dismissed'),
                },
            ],
            selectedState: {
                en_text: 'Filter by Status',
                text: this.$t('Filter by Status'),
            },
            isresubmitEmpty: false,
        }
    },
    async created() {
        this.changeTab(1)
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
        this.setUserType()
    },
    methods: {
        clearStatus() {
            this.selectedState = {
                en_text: 'Filter by Status',
                text: this.$t('Filter by Status'),
            }
        },
        search: debounce(function(val) {
            this.filters.search = val
            this.changeTab(2)
        }, 500),
        setUserType() {
            if (this.userAuth.id) {
                this.userData = cloneDeep(this.userAuth)
                if (this.userAuth.is_mentor) {
                    this.userType = 'mentor'
                } else {
                    this.userType = 'user'
                }
            }
        },
        goToSubmitIdea() {
            this.$router.push({
                name: 'submit-idea',
            })
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.activeIdeasLoadding) {
                    let next = this.getNextPage(this.ideasList.next)
                    if (next) {
                        this.activeIdeasLoadding = true
                        this.filters.page = next
                        this.getIdeas()
                    }
                }
            }
        },
        getIdeas() {
            IdeasHelper.list(this.filters)
                .then(() => {
                    this.activeIdeasLoadding = false
                })
                .catch(err => {
                    this.activeIdeasLoadding = false
                    console.log(err)
                })
        },
        changeTab(tab) {
            this.activeIdeasLoadding = true
            this.ideas = []
            this.activeTab = tab
            if (tab === 1) {
                delete this.filters.search
                this.filters.page_size = 6
                this.filters.page = 1
                this.filters.state = 'resubmit'
                this.getIdeas()
            } else if (tab === 2) {
                if (this.selectedState.en_text === 'Filter by Status')
                    delete this.filters.state
                else if (this.selectedState.en_text === 'approved')
                    this.filters.state = 'approved,planned,implemented'
                else if (this.selectedState.en_text === 'dismissed')
                    this.filters.state =
                        'attempted,dismissed,rejected,resubmitted'
                else this.filters.state = this.selectedState.en_text
                this.filters.page_size = 6
                this.filters.page = 1
                this.getIdeas()
            }
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['ideasList', 'userAuth', 'dashboardSettings']),
    },
    watch: {
        'ideasList.results': {
            handler: function(newVal) {
                let allIdeas = _.concat(this.ideas, newVal)
                this.ideas = cloneDeep(allIdeas)
                this.activeIdeasLoadding = false
            },
            immediate: true,
            deep: true,
        },
        selectedState() {
            this.changeTab(2)
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
        ideas() {
            if (
                this.ideas.length &&
                !this.isresubmitEmpty &&
                !this.activeIdeasLoadding
            ) {
                this.tabs.unshift({ title: 'Resubmit', order: 1 })
                this.isresubmitEmpty = true
            }
            if (
                !this.activeIdeasLoadding &&
                !this.ideas.length &&
                !this.isresubmitEmpty
            ) {
                this.changeTab(2)
                this.isresubmitEmpty = true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.filter-box {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 575px) {
        justify-content: center;
        flex-direction: column;
        margin-bottom: 60px;
    }
    .search-box {
        width: 50%;
        /deep/ {
            .form-control.is-valid {
                border-color: transparent;
                background-image: none !important;
                &:active,
                &:focus {
                    border-color: var(--primary);
                    background-color: var(--white);
                }
            }
        }
        @media screen and (max-width: 767px) {
            width: 50%;
        }
        @media screen and (max-width: 575px) {
            margin: auto;
            width: 100%;
            max-width: 300px;
        }
    }
    .dropdown-box {
        width: 30%;
        position: relative;
        /deep/ .v-select-toggle {
            padding: rem(10px) rem(60px) rem(10px) rem(40px) !important;
            height: 3.875rem;
            display: flex;
            align-items: center;
            color: var(--primary) !important;
            font-size: rem(18px) !important;
            box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
            .arrow-down {
                top: 35px;
                right: rem(32px);
                zoom: 0.6;
            }
            &:active,
            &:focus {
                color: #fff !important;
            }
        }
        /deep/ .v-dropdown-container {
            ul {
                max-height: max-content !important;
            }
        }
        @media screen and (max-width: 767px) {
            width: 40%;
        }
        @media screen and (max-width: 575px) {
            margin: auto;
            width: 100%;
            max-width: 300px;
        }
        .clear {
            width: 13px;
            position: absolute;
            top: 20px;
            right: 50px;
            cursor: pointer;
        }
    }
}
.no-idea-text {
    /deep/ a {
        color: var(--primary);
    }
}
/deep/ {
    .sidenav-wrapper {
        margin-top: 0;
        .right-col {
            @media screen and (min-width: 1601px) {
                padding-top: 95px;
            }
            @media screen and (max-width: 1600px) {
                padding-top: 82px;
            }
            @media screen and (max-width: 1500px) {
                padding-top: 108px;
            }
            @media screen and (max-width: 1399px) {
                padding-top: 120px;
            }
            @media screen and (max-width: 991px) {
                padding-top: 15px;
            }
            @media screen and (max-width: 767px) {
                padding-top: 85px;
            }
            @media screen and (max-width: 500px) {
                padding-top: 65px;
            }
            /deep/ {
                @media screen and (max-width: 991px) {
                    .page__title {
                        padding-left: 15px !important;
                    }
                }
            }
        }
    }
    .content-wrapper {
        padding-top: 17.75rem;
        @media screen and (max-width: 767px) {
            padding-top: 11.2rem;
        }
        @media screen and (max-width: 500px) {
            padding-top: 6.5rem;
        }
        .page__head {
            min-height: auto;
            @media screen and (max-width: 767px) {
                min-height: 126px !important;
            }
        }
    }
    .page-contents {
        padding-top: 3rem;
    }
    .listing__item--contents {
        flex: 0 0 60%;
        max-width: 60%;
    }
    .listing__item--category {
        @media screen and (min-width: 768px) {
            margin: 0;
            text-align: justify;
        }
    }
    .listing {
        &__item {
            margin-bottom: rem(65px);
            &--image {
                top: rem(-100px);
                img {
                    max-width: max-content;
                    object-fit: contain;
                    height: auto;
                }
            }
            &--title {
                padding-right: 0;
            }
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
            &__item {
                margin-bottom: rem(100px);
                &--body {
                    padding-top: rem(30px);
                    padding-bottom: rem(30px);
                }
            }
        }
        @media screen and (max-width: 767px) {
            &__item {
                &--body {
                    margin-top: rem(65px);
                    .d-flex {
                        flex-direction: column-reverse;
                    }
                }
                &--contents {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-top: -25px;
                }
                &--image {
                    top: rem(-60px);
                    img {
                        position: absolute;
                        left: 48%;
                        transform: translateX(-50%);
                        &.image-top {
                            position: relative;
                            left: 50%;
                        }
                    }
                }
            }
        }
    }
    .nav-tabs {
        .nav-item {
            max-width: 25% !important;
            flex: 0 0 25% !important;
            .nav-link {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .page-contents {
            padding-top: rem(65px);
        }
        .content-wrapper {
            .page__head {
                min-height: 114px;
                padding-right: 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .nav-tabs {
            flex-direction: column !important;
            width: 90%;
            margin: auto;
            border-color: transparent;
            .nav-item {
                display: inline-block;
                max-width: 100% !important;
                flex: 0 0 100% !important;
                box-shadow: -2px 0 20px rgba(0, 0, 0, 0.15) !important;
                background: var(--white);
                .nav-link {
                    &.active {
                        background-color: var(--primary) !important;
                    }
                }
            }
        }
        .sidenav-wrapper {
            margin-top: rem(36.4px);
        }
        .page-banner {
            --h: 452px;
        }
        .content-wrapper {
            .page__head {
                min-height: 126px;
            }
        }
    }
    @media screen and (max-width: 575px) {
        .page-banner {
            --h: 384px;
        }
        .page-contents {
            padding-top: 0;
        }
    }
}
.content-wrapper {
    .userAccount {
        .sidenav-wrapper {
            .right-col {
                /deep/ {
                    .tabs {
                        &.ideas-tabs {
                            padding-top: rem(16px) !important;
                            @media screen and (min-width: 1600px) {
                                padding-top: 0.9rem !important;
                            }
                            @media screen and (max-width: 1600px) and (min-width: 1400px) {
                                padding-top: rem(28.8px) !important;
                            }
                            .tab-content {
                                padding-top: rem(80px) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1100px) {
    /deep/ {
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    .ideas-tabs {
                        padding-top: 1.2rem !important;
                    }
                }
            }
        }
    }
}

// ar style

.ar {
    .clear {
        left: 50px !important;
        right: auto !important;
    }
    /deep/ {
        .listing {
            &__item--title {
                padding-left: 0;
            }
            @media screen and (max-width: 767px) {
                &__item {
                    &--image {
                        img {
                            right: 48%;
                            left: auto;
                            transform: translateX(50%);
                            &.image-top {
                                position: relative;
                                right: 50%;
                                left: auto;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            .content-wrapper {
                .page__head {
                    padding-left: 0;
                }
            }
        }
    }
    .content-wrapper {
        .page-contents {
            @media screen and (min-width: 1400px) {
                padding-top: 4rem;
            }
            @media screen and (max-width: 991px) {
                padding-top: 1rem;
            }
        }
        .userAccount {
            .sidenav-wrapper {
                .right-col {
                    /deep/ {
                        .tabs {
                            &.ideas-tabs {
                                padding-top: 0 !important;
                                @media screen and (max-width: 1600px) {
                                    padding-top: 1rem !important;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 991px) {
                        padding-top: 54px;
                    }
                    @media screen and (max-width: 767px) {
                        padding-top: 140px;
                    }
                    @media screen and (max-width: 575px) {
                        padding-top: 70px;
                    }
                    @media screen and (max-width: 500px) {
                        padding-top: 55px;
                    }
                    /deep/ {
                        @media screen and (max-width: 991px) {
                            .page__title {
                                padding-right: 15px !important;
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
