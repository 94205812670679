import { render, staticRenderFns } from "./UserIdeas.vue?vue&type=template&id=ea8b7034&scoped=true&"
import script from "./UserIdeas.vue?vue&type=script&lang=js&"
export * from "./UserIdeas.vue?vue&type=script&lang=js&"
import style0 from "./UserIdeas.vue?vue&type=style&index=0&id=ea8b7034&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea8b7034",
  null
  
)

export default component.exports