var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-inner-banner',{attrs:{"banner-image":_vm.pageBanner,"is-custom-banner":true,"banner":_vm.banner}}),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"container"},[_c('base-page-head',{attrs:{"page-head":_vm.pagehead,"show-profile-details":true,"user-data":_vm.userData,"user-type":_vm.userType}}),_c('div',{staticClass:"page-contents userAccount"},[_c('div',{staticClass:"sidenav-wrapper"},[_c('aside',{staticClass:"left-col"},[_c('account-nav')],1),_c('div',{staticClass:"right-col"},[_c('b-tabs',{staticClass:"ideas-tabs"},_vm._l((_vm.tabs),function(tab,index){return _c('b-tab',{key:index,attrs:{"title":_vm.$t(tab.title),"active":_vm.activeTab === tab.order},on:{"click":function($event){return _vm.changeTab(tab.order)}}},[(tab.order === 2)?_c('div',{staticClass:"filter-box"},[_c('div',{staticClass:"search-box"},[_c('base-input',{attrs:{"required":false,"placeholder":_vm.$t('Search')},on:{"input":_vm.search}})],1),_c('div',{staticClass:"dropdown-box"},[_c('v-select',{attrs:{"options":_vm.IdeaStates},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}),(
                                                _vm.selectedState.en_text !=
                                                    'Filter by Status'
                                            )?_c('div',{staticClass:"clear",on:{"click":_vm.clearStatus}},[_c('svg',{attrs:{"fill":"#009b74","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 409.81 409.81"}},[_c('use',{attrs:{"xlink:href":"#menu-close-icon"}})])]):_vm._e()],1)]):_vm._e(),_vm._l((_vm.ideas),function(idea,index){return _c('ideas-card',{key:("idea-" + index),attrs:{"description":false,"date":true,"idea":idea,"show-idea-state":false,"to":{
                                        name: 'user-idea-details',
                                        params: { uuid: idea.uuid },
                                    },"showReviews":false,"showIdeators":false,"showOpco":true,"showCategory":true}})}),(_vm.activeIdeasLoadding)?_c('base-loader'):_vm._e(),(
                                        !_vm.activeIdeasLoadding &&
                                            !_vm.ideas.length &&
                                            _vm.dashboardSettings &&
                                            _vm.dashboardSettings.dashboard_settings
                                    )?_c('p',{staticClass:"no-idea-text",domProps:{"innerHTML":_vm._s(
                                        _vm.dashboardSettings
                                            .dashboard_settings[
                                            ("no_idea_msg_" + (_vm.$i18n.locale))
                                        ]
                                    )}}):_vm._e()],2)}),1)],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }